<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 200.000000 200.000000"
    preserveAspectRatio="xMidYMid meet"
  >

    <g
      transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M144 1979 c-18 -20 -19 -51 -19 -979 0 -928 1 -959 19 -979 24 -26
60 -27 86 -1 19 19 20 33 20 375 l0 355 793 0 c756 0 795 1 810 18 36 39 23
64 -147 290 -90 120 -169 227 -176 238 -12 19 4 42 164 256 138 184 176 241
176 265 0 61 39 58 -827 61 l-793 2 0 40 c0 70 -64 105 -106 59z m1536 -234
c0 -3 -68 -95 -150 -205 -112 -149 -150 -207 -150 -228 0 -27 31 -72 278 -399
l24 -33 -716 0 -716 0 0 435 0 435 715 0 c393 0 715 -2 715 -5z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'FlagIcon',
  props: {
    width: {
      type: Number,
      default: 14,
    },
    height: {
      type: Number,
      default: 14,
    },
  },
}
</script>

<style lang="scss" scoped>
path {
  fill: var(--primary);
}

.dark-layout {
  path {
    fill: var(--primary);
  }
}
</style>
