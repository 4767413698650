<template>
  <div>
    <DxDataGrid
      ref="gridView"
      class="sxr-grid regular-text-color"
      :class="{ 'filter-panel-expanded': expandFilters }"
      :data-source="dataSource"
      :show-borders="false"
      :show-row-lines="true"
      :show-column-lines="false"
      :remote-operations="true"
      :word-wrap-enabled="true"
      :on-content-ready="onContentReady"
      :on-row-collapsed="onRowCollapsed"
      :on-row-expanding="onRowExpanding"
      :allow-column-reordering="!isCurrentBreakPointXs"
      :no-data-text="noResultsFoundMessage"
      width="100%"
      @exporting="onExporting"
      @exported="onExported"
    >
      <DxHeaderFilter
        :visible="false"
        :hide-select-all-on-search="false"
      />
      />
      <DxPaging :page-size="25" />
      <DxColumnChooser
        :enabled="!isCurrentBreakPointXs"
        mode="select"
      />
      <DxExport
        :enabled="true"
        :formats="['csv', 'xlsx']"
        :texts="{
          exportAll: 'Export to {0}'
        }"
      />
      <DxScrolling
        mode="virtual"
        :use-native="false"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="sales_grid_key"
      />

      <DxColumn
        :allow-exporting="false"
        :allow-sorting="false"
        :allow-reordering="false"
        data-field="image_url"
        caption=""
        data-type="string"
        :width="!isCurrentBreakPointXs ? '150' : '60'"
        cell-template="image-cell"
        :show-in-column-chooser="false"
        :visible-index="0"
        :css-class="!isCurrentBreakPointXs ? 'fixed-column-cell' : ''"
        :fixed="!isCurrentBreakPointXs"
        fixed-position="left"
      />

      <DxColumn
        data-field="asin"
        caption="ASIN"
        data-type="string"
        :min-width="100"
        :visible="false"
        :show-in-column-chooser="false"
      />
      <DxColumn
        data-field="marketplace"
        caption="Marketplace"
        data-type="string"
        :min-width="100"
        :visible="false"
        :show-in-column-chooser="false"
      />
      <DxColumn
        data-field="item_name"
        caption="Title"
        data-type="string"
        :min-width="300"
        :visible="false"
        :show-in-column-chooser="false"
      />
      <DxColumn
        data-field="currency"
        caption="Currency"
        data-type="string"
        :visible="false"
        :show-in-column-chooser="false"
      />

      <DxColumn
        :allow-exporting="false"
        :allow-reordering="false"
        :allow-header-filtering="false"
        :allow-sorting="false"
        :show-in-column-chooser="false"
        data-field="buttons"
        caption=""
        data-type="string"
        :width="!isCurrentBreakPointXs ? '50' : '40'"
        cell-template="buttons-cell"
        :visible-index="1"
        :css-class="!isCurrentBreakPointXs ? 'fixed-column-cell' : ''"
        :fixed="!isCurrentBreakPointXs"
        fixed-position="left"
        :render-async="true"
      />

      <DxColumn
        :show-in-column-chooser="!isCurrentBreakPointXs"
        :allow-header-filtering="false"
        :min-width="80"
        sort-order="desc"
        data-field="quantity_ordered"
        :caption="!isCurrentBreakPointXs ? 'Units Sold' : 'U. Sold'"
        :width="!isCurrentBreakPointXs ? '120' : '80'"
        data-type="string"
        cell-template="unitsSoldCell"
      />
      <DxColumn
        :allow-header-filtering="false"
        data-field="orders_quantity"
        :min-width="100"
        caption="Orders"
        data-type="string"
        cell-template="ordersQuantityCell"
      />
      <DxColumn
        :allow-header-filtering="false"
        data-field="revenue"
        caption="Revenue"
        data-type="string"
        :min-width="130"
        cell-template="money-cell"
        header-cell-template="captionWithQuestionMarkTemplate"
      />
      <DxColumn
        :allow-header-filtering="false"
        data-field="amazon_fees"
        caption="AMZ Fees"
        data-type="string"
        :min-width="130"
        cell-template="money-cell"
        header-cell-template="captionWithQuestionMarkTemplate"
      />
      <DxColumn
        :allow-header-filtering="false"
        data-field="net"
        caption="Net"
        data-type="string"
        :min-width="90"
        cell-template="money-cell"
        header-cell-template="captionWithQuestionMarkTemplate"
      />
      <DxColumn
        :allow-header-filtering="false"
        data-field="cost"
        caption="Cost"
        data-type="string"
        :min-width="90"
        cell-template="money-cell"
        header-cell-template="captionWithQuestionMarkTemplate"
      />
      <DxColumn
        :allow-header-filtering="false"
        data-field="profit"
        caption="Profit"
        data-type="string"
        :min-width="90"
        cell-template="money-cell"
        header-cell-template="captionWithQuestionMarkTemplate"
      />
      <DxColumn
        :allow-sorting="false"
        :allow-header-filtering="false"
        :min-width="60"
        data-field="profit_indicator"
        caption="Profit Margin"
        data-type="string"
        cell-template="margin-cell"
        :visible="false"
      />
      <DxColumn
        :allow-sorting="false"
        :allow-header-filtering="false"
        :min-width="60"
        data-field="profit_indicator_net"
        caption="Net Margin"
        data-type="string"
        cell-template="net-margin-cell"
        :visible="false"
      />
      <DxColumn
        data-field="promos"
        caption="Number of Promos"
        data-type="number"
        :visible="false"
        :show-in-column-chooser="false"
      />
      <DxColumn
        data-field="refund_quantity"
        caption="Number of Refunds"
        data-type="number"
        :visible="false"
        :show-in-column-chooser="false"
      />
      <DxColumn
        data-field="reimbs"
        caption="Number of Reimbursements"
        data-type="number"
        :visible="false"
        :show-in-column-chooser="false"
      />
      <DxMasterDetail
        :enabled="false"
        template="masterDetailTemplate"
      />

      <DxToolbar>
        <DxItem
          location="before"
          template="filterPanel"
        />
        <DxItem
          name="columnChooserButton"
        />
        <DxItem
          name="exportButton"
        />
      </DxToolbar>

      <!--    Toolbar -->
      <template #filterPanel>
        <div
          v-if="isCurrentBreakPointXs"
          class="filter-panel"
        >
          <div class="tw-flex tw-gap-2 tw-items-baseline">
            <div
              v-show="!isOrderDetailsAvailable"
              class="filter tw-flex-1"
            >
              <date-range
                v-model="filters.dateRange"
                :value="filters.dateRange"
                @update="onChangeDateRange"
              />
            </div>
            <div
              v-show="isOrderDetailsAvailable"
              class="filter tw-flex-1"
            >
              <b-form-input
                :value="orderPurchaseDate"
                :readonly="true"
              />
            </div>
            <DxButton
              icon="filter"
              type="default"
              width="35"
              height="35"
              :styling-mode="expandFilters ? 'contained' : 'outlined'"
              @click="onSearchButtonClick"
            />
          </div>
          <div
            v-if="expandFilters"
            class="tw-flex tw-flex-col tw-gap-3"
          >
            <div
              v-show="!isOrderDetailsAvailable"
              class="filter"
            >
              <DxTagBox
                v-model="filters.marketplaces"
                :data-source="marketplaces"
                :search-enabled="true"
                display-expr="title"
                value-expr="id"
                placeholder="All Marketplaces"
                item-template="item"
              >
                <template #item="{ data }">
                  <div class="d-flex flex-row justify-content-between align-items-center">
                    <div class="flex-1">
                      {{ data.title }}
                    </div>
                    <div>
                      <CountryFlag :country="data.country.toLowerCase()" />
                    </div>
                  </div>
                </template>
              </DxTagBox>
            </div>
            <div
              v-show="isOrderDetailsAvailable"
              class="filter"
            >
              <v-select
                v-model="orderMarketplace"
                :options="marketplaces"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :disabled="true"
                label="title"
                placeholder="All Marketplaces"
              />
            </div>
            <div class="filter">
              <b-form-input
                v-model="filters.skuOrAsin"
                :debounce="800"
                placeholder="Enter ASIN, SKU, keyword or order ID.."
              />
            </div>
          </div>
        </div>
        <div
          v-else
          class="filter-panel"
        >
          <div
            v-show="!isOrderDetailsAvailable"
            class="filter"
          >
            <date-range
              v-model="filters.dateRange"
              :value="filters.dateRange"
              @update="onChangeDateRange"
            />
          </div>
          <div
            v-show="isOrderDetailsAvailable"
            class="filter"
          >
            <b-form-input
              :value="orderPurchaseDate"
              :readonly="true"
            />
          </div>
          <div
            v-show="!isOrderDetailsAvailable"
            class="filter"
          >
            <DxTagBox
              v-model="filters.marketplaces"
              :data-source="marketplaces"
              :search-enabled="true"
              display-expr="title"
              value-expr="id"
              placeholder="All Marketplaces"
              item-template="item"
            >
              <template #item="{ data }">
                <div class="d-flex flex-row justify-content-between align-items-center">
                  <div class="flex-1">
                    {{ data.title }}
                  </div>
                  <div>
                    <CountryFlag :country="data.country.toLowerCase()" />
                  </div>
                </div>
              </template>
            </DxTagBox>
          </div>
          <div
            v-show="isOrderDetailsAvailable"
            class="filter"
          >
            <v-select
              v-model="orderMarketplace"
              :options="marketplaces"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :disabled="true"
              label="title"
              placeholder="All Marketplaces"
            />
          </div>
          <div class="filter">
            <b-form-input
              v-model="filters.skuOrAsin"
              :debounce="800"
              placeholder="Enter ASIN, SKU, keyword or order ID.."
            />
          </div>
        </div>
      </template>
      <!--    Columns-->
      <template #common-cell="{ data }">
        <div class="text-primary text-center">
          {{ data.value.display }}
        </div>
      </template>
      <template #ordersQuantityCell="{ data }">
        <div class="text-primary text-center d-flex flex-column">
          <div>{{ data.value.display }}</div>
          <div
            class="small"
            title="Avg Units per Order"
          >
            {{ data.data.quantity_per_order.toFixed(2) }}
          </div>
        </div>
      </template>
      <template #unitsSoldCell="{ data }">
        <div class="d-flex flex-column justify-content-center text-center">
          <div class="text-primary text-center">
            {{ data.value.display }}
          </div>
          <small
            v-if="data.key.promos.amount"
            class="text-primary"
          >{{ data.key.promos.display }} promos</small>
          <small
            v-if="data.key.refunds.amount"
            class="text-danger"
          >{{ data.key.refunds.display }} refunds</small>
          <small
            v-if="data.key.combined_reimbursements.amount"
            class="text-warning"
          >{{ data.key.combined_reimbursements.display }} reimbs</small>
        </div>
      </template>
      <template #money-cell="{ data }">
        <div
          class="text-center"
          :class="{ 'text-danger' : data.value && data.value.amount < 0, 'text-success': data.value && data.value.amount > 0 }"
        >
          {{ data.value.display }}
        </div>
      </template>
      <template #margin-cell="{ data }">
        <div class="d-flex flex-column justify-content-center text-center margin-cell">
          <div
            :class="{
              'text-success' : data.value.main.amount > 0,
              'text-danger' : data.value.main.amount <= 0,
            }"
          >{{ data.value.main.display }}</div>
          <div>ROI: <span
            :class="{
              'text-success' : data.value.alternative.amount > 0,
              'text-danger' : data.value.alternative.amount <= 0,
            }"
          >{{ data.value.alternative.display }}</span></div>
        </div>
      </template>
      <template #net-margin-cell="{ data }">
        <div class="d-flex flex-column justify-content-center text-center margin-cell">
          <div
            :class="{
              'text-success' : data.value.main.amount > 0,
              'text-danger' : data.value.main.amount <= 0,
            }"
          >{{ data.value.main.display }}</div>
        </div>
      </template>
      <template #buttons-cell="{ data }">
        <div
          class="d-flex flex-column justify-content-center tw-items-center"
          style="height: 100%"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-tooltip.hover.right="'Breakdown'"
            size="sm"
            class="btn-icon btn-xs"
            variant="flat-primary"
            @click.stop="onBreakdownClick(data)"
          >
            <b-spinner
              v-if="isFeeDetailsLoading && data.rowIndex === loadingRowIndex"
              small
              type="grow"
            />
            <feather-icon
              v-else
              icon="ChevronsDownIcon"
            />
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-tooltip.hover.right="'List of orders'"
            size="sm"
            class="btn-icon btn-xs"
            variant="flat-primary"
            @click.stop="onOrdersClick(data)"
          >
            <feather-icon icon="GridIcon" />
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-tooltip.hover.right="'Charts'"
            size="sm"
            class="btn-icon btn-xs "
            variant="flat-primary"
            @click.stop="onChartClick(data)"
          >
            <b-spinner
              v-if="isChartDataLoading && data.rowIndex === loadingRowIndex"
              small
              type="grow"
            />
            <feather-icon
              v-else
              icon="BarChart2Icon"
            />
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-tooltip.hover.right="'Breakdown by country'"
            size="sm"
            class="btn-icon btn-xs "
            variant="flat-primary"
            @click.stop="onByCountryClick(data)"
          >
            <FlagIcon />
          </b-button>
        </div>
      </template>
      <template #acos-cell="{ data }">
        <div
          class="d-flex flex-column justify-content-center text-center acos-cell"
          :class="{ mobile: isCurrentBreakPointXs }"
        >
          <div
            :class="{
              'text-success' : data.value.current.amount > 0,
              'text-danger' : data.value.current.amount <= 0,
            }"
          >{{ data.value.current.display }}</div>
          <small>Break-even: {{ data.value.max.display }}</small>
          <b-badge>
            TACOS {{ data.data.tacos.current.display }}
          </b-badge>
        </div>
      </template>
      <template #image-cell="{ data }">
        <div
          v-if="!data.data.is_totals"
          class="tw-flex tw-flex-col tw-items-center text-center image-cell"
        >
          <ProductImage
            :size="!isCurrentBreakPointXs ? '59px' : '40px'"
            :src="data.data.image_url"
            :title="data.data.title"
            icon-size="32"
            :is-zoom-available="true"
            :attr-class="!isCurrentBreakPointXs ? 'tw-p-4' : 'tw-p-1'"
            :asin="data.data.uuid"
            :product-url="data.data.product_url"
            :marketplace="data.data.marketplace.marketplace_id"
            :marketplace-flag="data.data.marketplace.country_code"
          />
          <div
            v-if="!isCurrentBreakPointXs"
            class="d-flex flex-column"
            style="min-height: 35px"
          >
            <a
              :href="data.data.product_url"
              target="_blank"
            >
              <small>
                {{ data.data.uuid }}
              </small>
            </a>
            <small>{{ data.data.short_name }}
            </small>
          </div>
        </div>
        <div
          v-else
          class="d-flex flex-column align-items-center text-center"
        >
          <div style="font-weight: 800; font-size: 1.3rem">
            Totals
          </div>
        </div>
      </template>
      <!--    Master Details -->
      <template #masterDetailTemplate="{ data }">
        <DetailTemplate
          v-if="isMarketplacesView"
          :is-totals="data.data.is_totals"
          :item="data.key"
          :filter-params="filterParams"
          :visible-columns="visibleColumns"
          :visible-columns-index="visibleColumnsIndex"
          :expanded-key="childMasterDetailExpandedKey"
          :mode="childMasterDetailMode"
          @master-detail-collapse="onMasterDetailCollapsed"
          @master-detail-expand="onMasterDetailExpand"
          @on-order-list-click="onOrderListClick"
          @on-breakdown-click="onMasterDetailBreakdownClick"
        />
        <div
          v-else
          style="height: 45vh; overflow: auto"
        >
          <Chart
            v-if="isChartView"
            :data-source="chartData"
            :settings="chartDataSettings"
          />
        </div>
      </template>
      <template #captionWithQuestionMarkTemplate="{ data }">
        <div class="d-flex flex-row">
          <div>{{ data.column.caption }}</div>
          <div
            v-if="getCaptionHint(data.column)"
            class="ml-50"
            @click.stop="false"
          >
            <feather-icon
              v-b-tooltip.hover="getCaptionHint(data.column)"
              icon="HelpCircleIcon"
            />
          </div>
        </div>
      </template>
      <div
        slot="buttonTemplate"
        class="export-button"
      >
        <div
          v-if="isExporting"
        >
          <b-spinner
            class="mr-50"
            small
            label="Small Spinner"
            type="grow"
          />
          <b-spinner
            class="mr-50"
            small
            label="Small Spinner"
            type="grow"
          />
          <b-spinner
            small
            label="Small Spinner"
            type="grow"
          />
        </div>
        <div v-else>
          <i class="dx-icon dx-icon-export" />
          <span>Export</span>
        </div>
      </div>
    </DxDataGrid>
    <OrderListModal
      v-model="showOrderList"
      :visible="showOrderList"
      :filter-params="orderListFilterParam"
      :item-data="orderModalData"
    />
    <BreakdownModal
      v-model="showBreakdown"
      :visible="showBreakdown"
      :items="breakdownModalData"
    />
  </div>
</template>

<script>
import {
  BFormInput, BBadge, BSpinner, BButton, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import moment from 'moment'
import {
  DxColumn,
  DxDataGrid,
  DxPaging,
  DxHeaderFilter,
  DxMasterDetail,
  DxColumnChooser,
  DxToolbar,
  DxItem,
  DxScrolling,
  DxStateStoring,
  DxExport,
} from 'devextreme-vue/data-grid'
import Ripple from 'vue-ripple-directive'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { saveAs } from 'file-saver-es'
import DxTagBox from 'devextreme-vue/tag-box'
import CountryFlag from 'vue-country-flag'
import DxButton from 'devextreme-vue/button'
import ListSettings from '@/views/sales/ListSettings'
import SalesDataSource from '@/data/sales.datasource'
import DateRange from '@/components/formElements/DateRange.vue'
import { findDateRangeKey, smartDateTagToDateRange } from '@/utils/utils'
import DetailTemplate from '@/views/sales_v2/components/DetailTemplate.vue'
import OrderListModal from '@/views/sales_v2/components/OrderListModal.vue'
import BreakdownModal from '@/views/sales_v2/components/BreakdownModal.vue'
import FlagIcon from '@/components/icons/FlagIcon.vue'
import ProductImage from '@/components/ProductImage.vue'

const Chart = () => import(/* webpackChunkName: "chartComp" */ '@/views/sales/Chart.vue')

const MASTER_DETAIL_MODE_CHART = 'chart'
const MASTER_DETAIL_MODE_MARKETPLACES = 'marketplaces'

export default {
  name: 'Sales',
  components: {
    ProductImage,
    FlagIcon,
    OrderListModal,
    DetailTemplate,
    BFormInput,
    DateRange,
    vSelect,
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxHeaderFilter,
    DxMasterDetail,
    DxColumnChooser,
    DxExport,
    DxToolbar,
    DxItem,
    DxScrolling,
    DxStateStoring,
    BBadge,
    BSpinner,
    BButton,
    Chart,
    DxTagBox,
    CountryFlag,
    BreakdownModal,
    DxButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    timeFilter: {
      type: String,
      default: undefined,
      required: false,
    },
  },
  data() {
    return {
      settingsIndex: 'sales-list',
      filters: {
        marketplaces: [],
        skuOrAsin: null,
        shippedPeriod: null,
        dateRange: {
          startDate: moment().hour(0).minute(0).second(0)
            .toDate(),
          endDate: moment().hour(23).minute(59).second(59)
            .toDate(),
        },
      },
      listSettings: {},
      noResultsFoundMessage: 'No Result Found',
      totals: {},
      orderDetails: {},
      isLoaded: false,
      selectedDateRange: null,
      isExporting: false,
      visibleColumns: [
        'quantity_ordered',
        'orders_quantity',
        'revenue',
        'profit',
        'profit_indicator',
        'acos',
      ],
      visibleColumnsIndex: [],
      childMasterDetailExpandedKey: undefined,
      childMasterDetailMode: MASTER_DETAIL_MODE_MARKETPLACES,
      masterDetailViewMode: MASTER_DETAIL_MODE_MARKETPLACES,
      expandedRows: {},
      isChartDataLoading: false,
      chartData: [],
      chartDataSettings: null,
      isFeeDetailsLoading: false,
      isFeeDetailsTotalsDataLoaded: false,
      feeDetailsDataSource: {},
      orderListFilterParam: {},

      loadingRowIndex: null,

      orderModalData: {},

      breakdownProductData: {},
      breakdownModalData: {},
      showOrderList: false,
      showBreakdown: false,

      captionHints: {
        revenue: 'Revenue is the total amount of sales.',
        amazon_fees: 'Amazon Fees is the total amount of fees charged by Amazon.',
        cost: 'Cost is the total amount of product cost.',
        net: 'NET is the total amount after deducting all the Amazon fees.',
        profit: 'Profit is the total amount after deducting all the Amazon fees and expenses.',
      },
      expandFilters: false,
    }
  },
  computed: {
    marketplaces() {
      return this.$store.getters['app/availableMarketplaceOptions']
    },
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
    isCurrentBreakPointXs() {
      return this.currentBreakPoint === 'xs'
    },
    columnWidth() {
      return this.isCurrentBreakPointXs ? '100' : '115'
    },
    filterParams() {
      const data = {
        marketplaces: this.filters.marketplaces,
        search: this.filters.skuOrAsin,
        search_by: 'all',

        sort_by: 'total_quantity_ordered',
        sort_direction: 'DESC',
      }

      if (this.selectedDateRange) {
        return {
          ...data,
          time_filter: this.selectedDateRange,
        }
      }

      return {
        ...data,
        start_date: moment(this.filters.dateRange.startDate).format('YYYY-MM-DDT00:00:00'),
        end_date: moment(this.filters.dateRange.endDate).format('YYYY-MM-DDT23:59:59'),
      }
    },
    dataSource() {
      return SalesDataSource.getInstance(this.$store, this.filterParams, this.onLoaded, this.onLoading, true)
    },
    isOrderDetailsAvailable() {
      if (!this.orderDetails) {
        return false
      }
      return Object.keys(this.orderDetails).length > 0
    },
    orderPurchaseDate() {
      if (this.isOrderDetailsAvailable) {
        return moment(this.orderDetails.purchase_date).format('MMM D, YYYY h:mm A')
      }
      return null
    },
    orderMarketplace() {
      if (this.isOrderDetailsAvailable) {
        return this.marketplaces.filter(marketplace => marketplace.id === this.orderDetails.marketplace_id)
      }
      return null
    },
    isChartView() {
      return this.masterDetailViewMode === MASTER_DETAIL_MODE_CHART
    },
    isMarketplacesView() {
      return this.masterDetailViewMode === MASTER_DETAIL_MODE_MARKETPLACES
    },
  },
  created() {
    if (this.$route.query.startDate) {
      this.filters.dateRange.startDate = moment(this.$route.query.startDate).toDate()
    }
    if (this.$route.query.endDate) {
      this.filters.dateRange.endDate = moment(this.$route.query.endDate).endOf('day').toDate()
    }
    if (!this.$route.query.startDate) {
      this.selectedDateRange = this.timeFilter || 'today'
    }
    if (this.selectedDateRange) {
      const [startDate, endDate] = smartDateTagToDateRange(this.selectedDateRange)
      this.filters.dateRange.startDate = startDate
      this.filters.dateRange.endDate = endDate
    }
    this.listSettings = new ListSettings()
    const stateSettings = this.$store.getters['app/getSettingsById'](this.settingsIndex)
    if (stateSettings) {
      this.listSettings = ListSettings.fromServerData(stateSettings)
    }
  },
  methods: {
    onSearchButtonClick() {
      this.expandFilters = !this.expandFilters
    },
    onActionClick({ itemData }) {
      switch (itemData.action) {
        case 'breakdown':
          this.onBreakdownClick(itemData.data)
          break
        case 'list_of_orders':
          this.onOrdersClick(itemData.data)
          break
        case 'chart':
          this.onChartClick(itemData.data)
          break
        case 'breakdown_by_country':
          this.onByCountryClick(itemData.data)
          break
        default:
          break
      }
    },
    actions(data) {
      return [
        {
          data: {
            rowIndex: data.rowIndex,
            key: data.key,
          },
          text: 'Breakdown',
          action: 'breakdown',
          icon: 'money',
        },
        {
          data: {
            rowIndex: data.rowIndex,
            key: data.key,
          },
          text: 'List Of Orders',
          action: 'list_of_orders',
          icon: 'contentlayout',
        },
        {
          data: {
            rowIndex: data.rowIndex,
            key: data.key,
          },
          text: 'Chart',
          action: 'chart',
          icon: 'chart',
        },
        {
          data: {
            rowIndex: data.rowIndex,
            key: data.key,
          },
          text: 'Breakdown By Country',
          action: 'breakdown_by_country',
          icon: 'globe',
        },
      ]
    },
    isEmpty(value) {
      return value === undefined || value === null || value.length === 0
    },
    onLoaded(data) {
      this.noResultsFoundMessage = data.noResultsFoundMessage
      this.totals = data.totals
      this.orderDetails = data.orderDetails
      this.isLoaded = true

      this.expandedRows = {}
    },
    onLoading(data) {
      this.isLoaded = !data
    },
    isFieldVisible(name) {
      return this.listSettings.isFieldVisible(name, this.currentBreakPoint)
    },
    onChangeDateRange(data) {
      const query = { ...this.$route.query }

      this.selectedDateRange = findDateRangeKey(data.rangeLabel)

      if (this.selectedDateRange) {
        this.$router.replace({ name: 'sales', params: { timeFilter: this.selectedDateRange } })
      } else {
        query.startDate = moment(data.startDate).format('YYYY-MM-DD')
        query.endDate = moment(data.endDate).format('YYYY-MM-DD')

        this.$router.replace({ name: 'sales', query })
      }
    },
    onListSettingsChange() {
      this.$store.dispatch('user/updateSettings', {
        index: this.settingsIndex,
        payload: this.listSettings.toServerData(),
      }).then(response => {
        this.$bvToast.toast(response.message, {
          title: 'User Settings',
          variant: 'success',
          solid: true,
        })
        this.$store.commit('app/UPDATE_APP_SETTINGS', {
          index: this.settingsIndex,
          value: this.listSettings.toServerData(),
        })
      }).catch(() => {
        this.$bvToast.toast('Server error, please try again later.', {
          title: 'Oops!',
          variant: 'danger',
          solid: true,
        })
      })
    },
    onExportClick() {
      this.isExporting = true
      this.$store.dispatch('sales/export', this.filterParams).then(({ data, headers }) => {
        // eslint-disable-next-line no-unused-vars
        const [matches, fileName] = headers['content-disposition'].match(/filename='(.*)'/)

        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName || 'sales.csv')
        document.body.appendChild(link)
        link.click()
      }).catch(() => {
        this.$bvToast.toast('Server error, please try again later.', {
          title: 'Oops!',
          variant: 'danger',
          solid: true,
        })
      }).finally(() => {
        this.isExporting = false
      })
    },
    onRowCollapsed() {
      this.masterDetailViewMode = MASTER_DETAIL_MODE_MARKETPLACES
    },
    onRowExpanding(e) {
      e.component.collapseAll(-1)
    },
    onContentReady({ component }) {
      this.visibleColumns = component.getVisibleColumns()
        .filter(item => item.dataField && item.dataField !== 'image_url')
        .map(item => item.dataField)

      this.visibleColumnsIndex = component.getVisibleColumns().map(item => ({
        name: item.dataField,
        index: item.visibleIndex,
      }))
    },
    onOrderListClick({ filterParams, data }) {
      this.orderListFilterParam = filterParams
      this.orderModalData = data
      this.showOrderList = true
    },
    onOrdersClick({ key }) {
      this.orderListFilterParam = {
        ...this.filterParams,
      }
      this.orderModalData = {}
      if (!key.is_totals) {
        if (!this.orderListFilterParam.search || !this.orderListFilterParam.search.length) {
          this.orderListFilterParam.search = key.uuid
          this.orderListFilterParam.search_by = 'asin'
          this.orderListFilterParam.asin = key.uuid
        } else {
          this.orderListFilterParam.filter_by_asin = [key.uuid]
        }

        this.orderModalData = {
          marketplace: key.marketplace,
          asin: key?.uuid,
          title: key?.title,
          image: key?.image_url,
        }
      }

      this.showOrderList = true
    },
    onByCountryClick({ key, rowIndex }) {
      if (this.expandedRows[rowIndex] && this.expandedRows[rowIndex] === MASTER_DETAIL_MODE_MARKETPLACES) {
        this.$refs.gridView.instance.collapseRow(key)
        delete this.expandedRows[rowIndex]
      } else {
        this.expandedRows[rowIndex] = MASTER_DETAIL_MODE_MARKETPLACES
        this.masterDetailViewMode = MASTER_DETAIL_MODE_MARKETPLACES
        this.$refs.gridView.instance.expandRow(key)
      }
    },
    onChartClick({ key, rowIndex }) {
      this.loadingRowIndex = rowIndex
      if (this.expandedRows[rowIndex] && this.expandedRows[rowIndex] === MASTER_DETAIL_MODE_CHART) {
        this.$refs.gridView.instance.collapseRow(key)
        delete this.expandedRows[rowIndex]
      } else {
        this.expandedRows[rowIndex] = MASTER_DETAIL_MODE_CHART
        this.masterDetailViewMode = MASTER_DETAIL_MODE_CHART

        this.isChartDataLoading = true
        const payload = { ...this.filterParams }

        if ((!payload.search || payload.search.length === 0) && key.uuid) {
          payload.search = key.uuid
          payload.search_by = 'asin'
        } else if (key.uuid) {
          payload.filter_by_asin = [key.uuid]
        }

        this.$store.dispatch('sales/fetchChartData', payload).then(result => {
          this.chartData = result.items.map(point => {
            const data = { ...point }
            data.date = moment(point.date).toDate()
            return data
          })
          this.chartDataSettings = result.settings || null
          this.$refs.gridView.instance.expandRow(key)
        }).finally(() => {
          this.isChartDataLoading = false
        })
      }
    },
    onMasterDetailBreakdownClick({ fees, data }) {
      this.breakdownModalData = fees
      this.breakdownProductData = data
      this.showBreakdown = true
    },
    onBreakdownClick({ rowIndex, key }) {
      if (!key.is_totals) {
        this.breakdownModalData = key.fees
        this.showBreakdown = true
        return
      }
      this.isFeeDetailsLoading = true
      this.loadingRowIndex = rowIndex
      const payload = { ...this.filterParams }
      if (this.marketplace) {
        payload.marketplaces = [this.marketplace]
      }

      if (key.uuid) {
        payload.search = key.uuid
        payload.search_by = 'asin'
      }
      this.$store.dispatch('sales/fetchFeeDetails', payload).then(result => {
        this.breakdownModalData = result
        this.showBreakdown = true
      }).finally(() => {
        this.isFeeDetailsLoading = false
      })
    },
    onMasterDetailCollapsed() {
      this.childMasterDetailExpandedKey = undefined
      this.childMasterDetailMode = MASTER_DETAIL_MODE_MARKETPLACES
    },
    onMasterDetailExpand({ key, mode }) {
      this.childMasterDetailExpandedKey = key
      this.childMasterDetailMode = mode
    },
    onExported(e) {
      e.component.columnOption('asin', 'visible', false)
      e.component.columnOption('refund_quantity', 'visible', false)
      e.component.columnOption('reimbs', 'visible', false)
      e.component.columnOption('promos', 'visible', false)
      e.component.columnOption('item_name', 'visible', false)
      e.component.columnOption('currency', 'visible', false)
      e.component.columnOption('marketplace', 'visible', false)
      e.component.endUpdate()
    },
    onExporting(e) {
      e.component.beginUpdate()
      e.component.columnOption('asin', 'visible', true)
      e.component.columnOption('refund_quantity', 'visible', true)
      e.component.columnOption('reimbs', 'visible', true)
      e.component.columnOption('promos', 'visible', true)
      e.component.columnOption('item_name', 'visible', true)
      e.component.columnOption('currency', 'visible', true)
      e.component.columnOption('marketplace', 'visible', true)

      const workbook = new Workbook()
      const worksheet = workbook.addWorksheet('Sales')

      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        const date = new Date()
        if (e.format === 'csv') {
          workbook.csv.writeBuffer().then(buffer => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `Sales ${date.toLocaleDateString('en-US')}.csv`)
          })
        } else {
          workbook.xlsx.writeBuffer().then(buffer => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `Sales ${date.toLocaleDateString('en-US')}.xlsx`)
          })
        }
      })
      e.cancel = true
    },
    getCaptionHint(column) {
      return this.captionHints[column.dataField] || undefined
    },
  },
}
</script>
<style lang="scss">
@import '@/assets/scss/common/grid.scss';
.dark-layout .dx-popup-wrapper > .dx-overlay-content {
  border: 1px solid #404656;
  background: #353d55;
}
.dxc-tooltip {
  z-index: 2 !important;
}
</style>
<style lang="scss" scoped>
  .sxr-grid {
    min-height: calc(100dvh - 120px);
    max-height: calc(100dvh - 120px);

    .margin-cell,
    .acos-cell {
      min-height: 75px;
      max-height: 75px;

      &.mobile {
        min-height: 35px;
        max-height: 35px;
      }
    }

    .dx-toolbar {
      .dx-item {
        .export-button {
          height: 34px;
          width: 104px;
          display: flex;
          flex-flow: column;
          justify-content: center;
        }
      }
    }
    .sp-list-view {
      .dx-list-item {
        border-top: 1px solid #ebe9f1;
        .list-item {
          .list-item-field {
            .sku {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
</style>
<style lang="scss">
.cell-text {
  font-size: 1.3rem;
  font-weight: 500;
  text-align: center;
}

.sp-list-view {
  .list-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    color: #6e6b7b;
    font-size: 1.1rem;
    font-weight: bold;
    background-color: #f3f2f7;
    position: relative;
    padding: 6px 0px 6px 0px;
    border-radius: 0.428rem 0.428rem 0 0;

    .list-header-field {
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
      width: 10vw;
      position: relative;

      &.buttons {
        max-width: 0px;
      }
    }

    .list-header-setting {
      position: absolute;
      left: 1rem;
      width: 25px;
      padding: 0px;
    }
  }

  .expand-item {
    position: absolute;
    top: 50%;
    left: 1.5rem;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 768px) {
    .expand-item {
      left: 0.5rem;
    }
  }

  .dx-list .dx-empty-message,
  .dx-list-item {
    border-top: 1px solid #ebe9f1;
    .dx-list-item-content {
      &::before {
        content: none;
      }
    }
    .list-item {
      justify-content: flex-end;
      padding: 0;
      flex-wrap: nowrap;
      display: flex;

      .list-item-field {
        display: flex;
        flex: 1;
        font-size: 1.5rem;
        font-weight: 500;
        text-align: center;
        justify-content: center;
        align-items: center;
        padding: 0px;
        position: relative;

        &.buttons {
          max-width: 35px;
        }
      }
    }
  }
  .dx-list-item-content {
    padding: 0;
  }
  .total-item {
    justify-content: flex-end;
    padding: 10px 0px 10px 0px;
    flex-wrap: nowrap;
    display: flex;

    .list-item-field {
      display: flex;
      flex: 1;
      font-size: 1.5rem;
      font-weight: 500;
      text-align: center;
      justify-content: center;
      align-items: center;
      padding: 0px;
      position: relative;
    }
  }

  .dx-empty-message {
    text-align: center;
  }
}
.dark-layout {
  .sp-list-view {
    .list-header {
      color: #d0d2d6;
      background-color: #343d55;
    }

    .dx-list .dx-empty-message,
    .dx-list-item {
      border-top: 1px solid #3b4253;
    }
  }
}
</style>
