<template>
  <DxDataGrid
    ref="dxDataGrid"
    class="sxr-grid regular-text-color"
    :data-source="dataSource"
    :show-borders="false"
    :show-row-lines="true"
    :show-column-lines="false"
    :remote-operations="true"
    :column-auto-width="true"
    :allow-column-reordering="!isCurrentBreakPointXs"
    :no-data-text="noResultsFoundMessage"
    width="100%"
    :height="gridHeight"
  >
    <DxStateStoring
      :enabled="true"
      type="localStorage"
      storage-key="orders-dx-grid-state"
    />
    <DxHeaderFilter
      :visible="false"
      :hide-select-all-on-search="false"
    />
    />
    <DxPaging :page-size="25" />
    <DxColumnChooser
      :enabled="!isCurrentBreakPointXs"
      mode="select"
    />
    <DxExport
      :enabled="false"
    />
    <DxScrolling
      mode="virtual"
    />
    <DxColumn
      css-class="fixed-column-cell"
      data-field="id"
      caption="Orders"
      alignment="center"
      :fixed="true"
      :allow-sorting="false"
      fixed-position="left"
      :min-width="ordersColumnMinWidth"
      :width="ordersColumnMinWidth"
      cell-template="ordersCellTemplate"
    />
    <DxColumn
      data-field="quantity.amount"
      caption="Qty"
      alignment="center"
      :min-width="85"
      :width="85"
      :allow-sorting="false"
    />
    <DxColumn
      data-field="revenue.display"
      caption="Net Rev"
      data-type="string"
      alignment="center"
      :min-width="100"
      cell-template="revenueTemplate"
      :allow-sorting="false"
    />
    <DxColumn
      data-field="profit.display"
      caption="Profit"
      data-type="string"
      alignment="center"
      :min-width="130"
      cell-template="profitTemplate"
      :allow-sorting="false"
    />
    <DxColumn
      data-field="profit_indicator"
      caption="Margin"
      data-type="string"
      alignment="center"
      :min-width="130"
      :allow-sorting="false"
      cell-template="marginCellTemplate"
    />
    <DxMasterDetail
      :enabled="false"
      template="masterDetailTemplate"
    />
    <template #masterDetailTemplate>
      <div style="height: 45vh">
        <DxScrollView>
          <ChargesBreakdown
            :fees-details="feesData"
            :has-refound="false"
          />
        </DxScrollView>
      </div>
    </template>
    <template #ordersCellTemplate="{ data }">
      <div class="d-flex flex-column align-items-center">
        <div @dblclick="copyToClipboard(formatDate(data.data.purchase_date_user_timezone))">
          {{ formatDate(data.data.purchase_date_user_timezone) }}
        </div>
        <div
          v-if="!isCurrentBreakPointXs"
          class="d-flex flex-row flex-gap align-items-center justify-content-center"
        >
          <feather-icon
            v-if="data.data.status === 'Pending'"
            v-b-tooltip.hover="'Order includes estimates until finalized by Amazon.'"
            class="text-info"
            title="Order includes estimates until finalized by Amazon."
            icon="InfoIcon"
          />
          <feather-icon
            v-if="data.data.promotion_id"
            v-b-tooltip.hover="'Promotion'"
            class="text-info"
            title="Promotion"
            icon="TagIcon"
          />
          <feather-icon
            v-if="data.data.is_business_order"
            v-b-tooltip.hover="'Business Order'"
            class="text-info"
            title="Business Order"
            icon="BriefcaseIcon"
          />
          <div>
            <country-flag
              size="small"
              :country="data.data.marketplace.country_code.toLowerCase()"
            />
          </div>
          <small
            @dblclick="copyToClipboard(data.data.id)"
          >{{ data.data.id }}</small>
          <feather-icon
            v-b-tooltip.hover="'Copy To Clipboard'"
            class="text-info"
            title="Copy To Clipboard"
            icon="CopyIcon"
            @click.stop="copyToClipboard(data.data.id)"
          />
        </div>
        <div
          v-else
          class="d-flex flex-column"
        >
          <div class="d-flex flex-row flex-gap justify-content-center">
            <div>
              <country-flag
                size="small"
                :country="data.data.marketplace.country_code.toLowerCase()"
              />
            </div>
            <small
              @dblclick="copyToClipboard(data.data.id)"
            >{{ data.data.id }}</small>
            <feather-icon
              v-b-tooltip.hover="'Copy To Clipboard'"
              class="text-info"
              title="Copy To Clipboard"
              icon="CopyIcon"
              @click.stop="copyToClipboard(data.data.id)"
            />
          </div>
          <div class="d-flex flex-row flex-gap justify-content-center">
            <feather-icon
              v-if="data.data.status === 'Pending'"
              v-b-tooltip.hover="'Order includes estimates until finalized by Amazon.'"
              class="text-info"
              title="Order includes estimates until finalized by Amazon."
              icon="InfoIcon"
            />
            <feather-icon
              v-if="data.data.promotion_id"
              v-b-tooltip.hover="'Promotion'"
              class="text-info"
              title="Promotion"
              icon="TagIcon"
            />
            <feather-icon
              v-if="data.data.is_business_order"
              v-b-tooltip.hover="'Business Order'"
              class="text-info"
              icon="BriefcaseIcon"
            />
          </div>
        </div>
      </div>
    </template>
    <template #revenueTemplate="{ data }">
      <div
        :class="{
          'text-success': data.data.revenue.amount >= 0,
          'text-danger': data.data.revenue.amount < 0
        }"
      >{{ data.data.revenue.display }}
      </div>
    </template>
    <template #profitTemplate="{ data }">
      <div class="d-flex flex-column">
        <div
          :class="{
            'text-success': data.data.profit.amount >= 0,
            'text-danger': data.data.profit.amount < 0
          }"
        >{{ data.data.profit.display }}
        </div>
        <b-button
          size="xs"
          variant="outline-secondary"
          @click.stop="onBreakdownClick(data)"
        >
          Breakdown
          <b-spinner
            v-if="feesDetailsLoading && currentBreakdownId === data.data.id"
            small
            type="grow"
          />
          <feather-icon
            v-else
            icon="ChevronsDownIcon"
          />
        </b-button>
      </div>
    </template>
    <template #marginCellTemplate="{ data }">
      <div
        :class="{
          'text-success' : data.value.main.amount > 0,
          'text-danger' : data.value.main.amount <= 0,
        }"
      >{{ data.value.main.display }}</div>
    </template>
  </DxDataGrid>
</template>

<script>
import CountryFlag from 'vue-country-flag'
import { DxScrollView } from 'devextreme-vue/scroll-view'
import {
  DxColumn,
  DxColumnChooser,
  DxDataGrid, DxExport,
  DxHeaderFilter,
  DxMasterDetail,
  DxPaging, DxScrolling,
  DxStateStoring,
} from 'devextreme-vue/data-grid'
import moment from 'moment/moment'
import { BButton, BSpinner, VBTooltip } from 'bootstrap-vue'
import OrdersDataSource from '@/data/orders.datasource'
import ChargesBreakdown from '@/views/sales_v2/components/ChargesBreakdown.vue'

export default {
  name: 'OrdersTable',
  components: {
    ChargesBreakdown,
    DxScrollView,
    CountryFlag,
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxHeaderFilter,
    DxMasterDetail,
    DxColumnChooser,
    DxExport,
    DxScrolling,
    DxStateStoring,
    BButton,
    BSpinner,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    gridHeight: {
      type: String,
      default: '100%',
    },
    filterParams: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      noResultsFoundMessage: 'No Result Found',
      feesDetailsLoading: false,
      feesData: {},
      currentBreakdownId: null,
    }
  },
  computed: {
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
    isCurrentBreakPointXs() {
      return this.currentBreakPoint === 'xs'
    },
    containerHeight() {
      return this.isCurrentBreakPointXs ? '90vh' : '80vh'
    },
    ordersColumnMinWidth() {
      return this.isCurrentBreakPointXs ? 180 : 250
    },
    dataSource() {
      const filterParams = { ...this.filterParams }
      return OrdersDataSource.getInstance(this.$store, filterParams)
    },
  },
  methods: {
    onBreakdownClick({ key, data }) {
      this.$refs.dxDataGrid.instance.collapseAll(-1)
      if (this.currentBreakdownId && this.currentBreakdownId === data.id) {
        this.currentBreakdownId = null
        return
      }

      this.feesDetailsLoading = true
      this.currentBreakdownId = data.id

      const payload = {
        search: data.id,
      }

      this.$store.dispatch('sales/fetchFeeDetails', payload).then(result => {
        this.feesData = result
        this.feesDetailsLoading = false
        this.$refs.dxDataGrid.instance.expandRow(key)
        // eslint-disable-next-line no-unused-vars
      }).catch(Err => {
      }).finally(() => {
        this.feesDetailsLoading = false
      })
    },
    formatDate(date) {
      return moment(date).utc().format('MMM D, YYYY h:mm A')
    },
    async copyToClipboard(value) {
      await navigator.clipboard.writeText(value)
      this.$bvToast.toast(value, {
        title: 'Copy To Clipboard',
        variant: 'info',
        solid: true,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/common/grid.scss';
.dark-layout .dx-popup-wrapper > .dx-overlay-content {
  border: 1px solid #404656;
  background: #353d55;
}
</style>
