import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'

export default class {
  static getInstance(store, params, loadedCallback, loadingChangedCallback, joinWithTotals) {
    return new DataSource({
      store: new CustomStore({
        load: options => {
          const payload = { ...params }
          payload.take = options.take || 25
          payload.skip = options.skip || 0

          if (options.dataField) {
            const fieldMapping = {
              image_url: 'asin',
            }
            return store.dispatch('sales/fetchSalesField', { ...payload, field_name: fieldMapping[options.dataField] ?? options.dataField })
              .then(result => result.map(item => ({ key: item.value })))
          }

          if (options.sort && options.sort[0]) {
            const mapping = {
              quantity_ordered: 'total_quantity_ordered',
              orders_quantity: 'total_orders',
            }
            payload.sort_by = mapping[options.sort[0].selector] ?? options.sort[0].selector
            payload.sort_direction = options.sort[0].desc ? 'DESC' : 'ASC'
          }
          if (options.filter) {
            if (options.filter[0] === 'image_url') {
              payload.filter_by_asin = [options.filter[2]]
            } else {
              payload.filter_by_asin = options.filter.filter(item => Array.isArray(item) && item[0] === 'image_url').map(item => item[2])
            }
          }

          if (options.isLoadingAll) {
            return store.dispatch('sales/export', payload).then(result => result)
          }

          return store.dispatch('sales/fetchSales', payload).then(result => {
            loadedCallback({
              noResultsFoundMessage: result?.no_result_message ?? '',
              totals: { ...result?.totals, is_totals: true } ?? {},
              orderDetails: result?.order_details ?? {},
              totalItems: result?.total_items ?? 0,
            })

            if (!result?.total_items) {
              return {
                data: [],
                totalCount: 0,
              }
            }

            let data = []
            let totalCount = result?.total_items ?? 0

            if (payload.skip === 0 && joinWithTotals === true) {
              data = [{
                ...(result?.totals ?? {}),
                is_totals: true,
              }].concat(result?.items ?? [])
              totalCount += 1
            } else {
              data = result?.items ?? []
            }

            return {
              data,
              totalCount,
            }
          })
        },
      }),
      paginate: true,
      pageSize: 25,
      onLoadingChanged(isLoading) {
        loadingChangedCallback(isLoading)
      },
    })
  }
}
