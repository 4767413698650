var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"charges-details-container"},[_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("Account "+_vm._s(_vm.currency))]),_c('th',[_vm._v("Orders")]),(_vm.hasRefound)?_c('th',[_vm._v(" Refunds ")]):_vm._e()])]),_c('tbody',_vm._l((_vm.fees),function(fee){return _c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.advancedView || (!_vm.advancedView && fee.isSummary)),expression:"advancedView || (!advancedView && fee.isSummary)"}],key:fee.tag,class:{
          summary: fee.isSummary,
        }},[_c('td',[_vm._v(_vm._s(_vm.feeNameMapping(fee.name)))]),(!fee.isSum)?_c('td',[_c('span',{class:{
              'positive': fee.order.amount >= 0,
              'negative': fee.order.amount < 0
            }},[_vm._v(" "+_vm._s(fee.order.display_number)+" ")])]):_vm._e(),(!fee.isSum && _vm.hasRefound)?_c('td',[_c('span',{class:{
              'positive': fee.return.amount >= 0,
              'negative': fee.return.amount < 0
            }},[_vm._v(_vm._s(fee.return.display_number)+" ")])]):_vm._e(),(fee.isSum)?_c('td',{attrs:{"colspan":"2"}},[_c('span',{class:{
              'positive': fee.total.amount >= 0,
              'negative': fee.total.amount < 0
            }},[_vm._v(_vm._s(fee.total.display_number)+" ")])]):_vm._e()])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }