<template>
  <b-modal
    v-model="isVisible"
    size="lg"
    title="Breakdown"
    scrollable
    :hide-footer="true"
  >
    <div class="d-flex flex-1 flex-column">
      <div class="p-1 pb-0">
        <b-form-checkbox
          id="remember-me"
          v-model="advancedView"
          name="checkbox-1"
          @change="onViewModeChange"
        >
          Advanced View
        </b-form-checkbox>
      </div>
      <div class="d-flex flex-column overflow-auto table-fix-head">
        <ChargesBreakdown
          :fees-details="items"
          :advanced-view="advancedView"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import { BFormCheckbox, BModal } from 'bootstrap-vue'
import ChargesBreakdown from '@/views/sales_v2/components/ChargesBreakdown.vue'

export default {
  name: 'BreakdownModal',
  components: {
    BFormCheckbox,
    ChargesBreakdown,
    BModal,
  },
  props: {
    items: {
      type: Object,
      default: () => ({}),
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      advancedView: false,
    }
  },
  computed: {
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
    isCurrentBreakPointXs() {
      return this.currentBreakPoint === 'xs'
    },
    isVisible: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  mounted() {
    this.advancedView = localStorage.getItem('breakdown_view_mode') === '1'
  },
  methods: {
    onViewModeChange(e) {
      localStorage.setItem('breakdown_view_mode', e ? 1 : 0)
    },
  },
}
</script>

<style lang="scss" scoped>
  ::v-deep {
    .modal-body {
      display: flex;
      align-items: stretch;
      justify-content: center;
    }

    .table-fix-head {
      thead {
        tr {
          position: sticky;
          top: -1px;
          z-index: 1;
        }
      }
    }
  }
</style>
