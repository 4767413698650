<template>
  <div class="charges-details-container">
    <table>
      <thead>
        <tr>
          <th>Account {{ currency }}</th>
          <th>Orders</th>
          <th v-if="hasRefound">
            Refunds
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="fee in fees"
          v-show="advancedView || (!advancedView && fee.isSummary)"
          :key="fee.tag"
          :class="{
            summary: fee.isSummary,
          }"
        >
          <td>{{ feeNameMapping(fee.name) }}</td>
          <td v-if="!fee.isSum">
            <span
              :class="{
                'positive': fee.order.amount >= 0,
                'negative': fee.order.amount < 0
              }"
            >
              {{ fee.order.display_number }}
            </span>
          </td>
          <td v-if="!fee.isSum && hasRefound">
            <span
              :class="{
                'positive': fee.return.amount >= 0,
                'negative': fee.return.amount < 0
              }"
            >{{ fee.return.display_number }}
            </span>
          </td>
          <td
            v-if="fee.isSum"
            colspan="2"
          >
            <span
              :class="{
                'positive': fee.total.amount >= 0,
                'negative': fee.total.amount < 0
              }"
            >{{ fee.total.display_number }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'ChargesBreakdown',
  props: {
    hasRefound: {
      type: Boolean,
      default: true,
    },
    advancedView: {
      type: Boolean,
      default: false,
    },
    feesDetails: {
      type: Object,
      required: true,
    },
  },
  computed: {
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
    isCurrentBreakPointXs() {
      return this.currentBreakPoint === 'xs'
    },
    currency() {
      return `(${this.feesDetails.main_fee.total_charges.currency})`
    },
    fees() {
      const items = []
      // eslint-disable-next-line no-restricted-syntax
      for (const feeGroup of this.feesDetails.main_fee.fee_groups) {
        if (feeGroup.tag === 'cost') {
          items.push({
            isSummary: true,
            tag: 'net',
            name: 'NET',
            order: this.feesDetails.main_fee.order_net,
            return: this.feesDetails.main_fee.return_net,
          })
          items.push({
            isSummary: true,
            isSum: true,
            tag: 'net_after_refunds',
            name: 'NET After Refunds.',
            total: this.feesDetails.main_fee.total_net,
          })
        }
        // eslint-disable-next-line no-restricted-syntax
        for (const fee of feeGroup.fees) {
          if (fee.order.amount !== 0 || fee.return.amount !== 0) {
            items.push({
              tag: fee.tag,
              name: fee.name,
              order: fee.order,
              return: fee.return,
            })
          }
        }

        if (feeGroup.tag === 'cost') {
          items.push({
            isSummary: true,
            isSum: true,
            tag: feeGroup.tag,
            name: feeGroup.name,
            total: feeGroup.total_charges,
          })
        } else if (feeGroup.order.amount !== 0 || feeGroup.return.amount !== 0) {
          items.push({
            isSummary: true,
            tag: feeGroup.tag,
            name: feeGroup.name,
            order: feeGroup.order,
            return: feeGroup.return,
          })
        }

        if (feeGroup.tag === 'amazon_fees') {
          items.push({
            isSummary: true,
            isSum: true,
            tag: 'amazon_fees_after_refunds',
            name: 'Amazon Fees After Refunds.',
            total: feeGroup.total_charges,
          })
        }

        if (feeGroup.tag === 'revenue') {
          items.push({
            isSummary: true,
            isSum: true,
            tag: 'revenue_after_refunds',
            name: 'NET Revenue After Refunds.',
            total: feeGroup.total_charges,
          })
        }
      }

      items.push({
        isSummary: true,
        tag: 'profit',
        name: 'Profit',
        order: this.feesDetails.main_fee.order,
        return: this.feesDetails.main_fee.return,
      })

      items.push({
        isSummary: true,
        isSum: true,
        tag: 'profit_after_refunds',
        name: 'Profit After Refunds',
        total: this.feesDetails.main_fee.total_charges,
      })

      if (this.feesDetails.misc_fee) {
        // eslint-disable-next-line no-restricted-syntax
        for (const feeGroup of this.feesDetails.misc_fee.fee_groups) {
          // eslint-disable-next-line no-restricted-syntax
          for (const fee of feeGroup.fees) {
            if (fee.order) {
              items.push({
                tag: fee.tag,
                name: fee.name,
                order: fee.order,
                return: fee.return,
              })
            } else {
              items.push({
                isSum: true,
                tag: fee.tag,
                name: fee.name,
                total: fee.total_charges,
              })
            }
          }
          items.push({
            isSummary: true,
            isSum: true,
            tag: feeGroup.tag,
            name: feeGroup.name,
            total: feeGroup.total_charges,
          })
        }

        items.push({
          isSummary: true,
          isSum: true,
          tag: 'profit_after_adj',
          name: 'Profit After Adj.',
          total: this.feesDetails.misc_fee.total_profit,
        })
      }

      return items
    },
  },
  methods: {
    feeNameMapping(name) {
      const mapping = {
        Revenue: 'NET Revenue (Incl. Sales Tax/VAT)',
      }
      return mapping[name] || name
    },
  },
}
</script>

<style lang="scss" scoped>
  .dark-layout {
    .charges-details-container {
      table {
        thead {
          tr {
            border: 1px solid #343d55;
            background-color: #343d55;
            color: #fff;
          }
        }
        tbody {
          tr {
            td {
              padding: 0.5rem;
              border: 1px solid #343d55;
              border-top: 0;
              border-bottom: 0;
              color: #fff;
            }
            &.summary {
              td {
                border-bottom: 5px solid #343d55;
                &:not(:first-child) {
                  border-top: 1px solid #343d55;
                }
              }
            }
          }
        }
      }
    }
  }
  .charges-details-container {
    padding: 1rem;
    table {
      width: 100%;
      margin-bottom: 1rem;
      font-family: "Helvetica Neue","Segoe UI",helvetica,verdana,sans-serif;
      thead {
        tr {
          border: 1px solid #f3f2f7;
          background-color: #f3f2f7;
          font-weight: 800;
          font-size: 12px;
          letter-spacing: 0.6px;
          text-transform: uppercase;
          color: #6e6b7b;
          th {
            padding: 0.5rem;
            text-align: center;
          }
        }
      }
      tbody {
        tr {
          td {
            padding: 0.5rem;
            border: 1px solid #f3f2f7;
            border-top: 0;
            border-bottom: 0;
            color: #424242;
            text-align: center;
            font-size: 14px !important;
            &:first-child {
              text-align: left;
            }
            span {
              &.positive {
                color: var(--success) !important;
              }
              &.negative {
                color: var(--danger) !important;
              }
            }
          }
          &.summary {
            border-bottom: 5px solid #f3f2f7;
            td {
              font-weight: 800;
              &:first-child {
                text-align: left;
                font-weight: 800;
              }
              &:not(:first-child) {
                border-top: 1px solid #f3f2f7;
              }
            }
          }
        }
      }
    }
  }
</style>
