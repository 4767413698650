export default class ListSettings {
  constructor() {
    this.fields = {
      sku: {
        content: {
          sku: true,
          asin: true,
        },
      },
      unitsSold: {
        content: {
          promos: true,
          reimbs: true,
          refunds: true,
          hoverTooltip: true,
        },
        visible: true,
      },
      orders: {
        visible: true,
        breakPoint: ['sm', 'md', 'xl', 'lg'],
      },
      revenue: {
        visible: true,
        breakPoint: ['sm', 'md', 'xl', 'lg'],
      },
      profit: {
        visible: true,
      },
      margin: {
        content: {
          roi: true,
          hoverTooltip: true,
        },
        visible: true,
      },
      acos: {
        content: {
          breakEven: true,
          tacos: true,
          hoverTooltip: true,
        },
        visible: true,
        breakPoint: ['sm', 'md', 'xl', 'lg'],
      },
    }
  }

  toServerData() {
    return this.fields
  }

  static fromServerData(data) {
    const instance = new ListSettings()
    Object.assign(instance.fields, data)
    return instance
  }

  isFieldVisible(name, currentBreakPoint) {
    try {
      if (this.fields[name].breakPoint && !this.fields[name].breakPoint.includes(currentBreakPoint)) {
        return false
      }
      return this.fields[name].visible
      // eslint-disable-next-line no-empty
    } catch (Error) {
    }

    return true
  }
}
