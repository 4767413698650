import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'

export default class {
  static getInstance(store, params) {
    return new DataSource({
      store: new CustomStore({
        load: options => {
          const payload = { ...params }
          payload.take = options.take
          payload.skip = options.skip ?? 0

          return store.dispatch('sales/fetchOrders', payload).then(result => {
            if (!result.items) {
              return []
            }

            return {
              data: result.items,
              totalCount: result.total_items,
            }
          })
        },
      }),
      paginate: true,
      pageSize: 25,
    })
  }
}
