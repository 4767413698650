<template>
  <DxDataGrid
    ref="gridView"
    class="marketplace-grid"
    :data-source="dataSource"
    :show-borders="false"
    :show-row-lines="true"
    :show-column-lines="false"
    :remote-operations="true"
    :word-wrap-enabled="true"
    :show-column-headers="false"
  >
    <DxColumn
      :allow-sorting="false"
      data-field="image_url"
      caption=""
      data-type="string"
      :width="!isCurrentBreakPointXs ? '150' : '60'"
      cell-template="image-cell"
      :css-class="!isCurrentBreakPointXs ? 'fixed-column-cell' : ''"
      :fixed="!isCurrentBreakPointXs"
      fixed-position="left"
    />
    <DxColumn
      data-field="asin"
      caption="ASIN"
      data-type="string"
      :min-width="100"
      :visible="false"
      :show-in-column-chooser="false"
    />
    <DxColumn
      data-field="marketplace"
      caption="Marketplace"
      data-type="string"
      :min-width="100"
      :visible="false"
      :show-in-column-chooser="false"
    />
    <DxColumn
      data-field="item_name"
      caption="Title"
      data-type="string"
      :min-width="300"
      :visible="false"
      :show-in-column-chooser="false"
    />
    <DxColumn
      data-field="currency"
      caption="Currency"
      data-type="string"
      :visible="false"
      :show-in-column-chooser="false"
    />
    <DxColumn
      :allow-header-filtering="false"
      :allow-sorting="false"
      :show-in-column-chooser="false"
      data-field="buttons"
      caption=""
      data-type="string"
      :width="!isCurrentBreakPointXs ? '50' : '40'"
      cell-template="buttons-cell"
      :css-class="!isCurrentBreakPointXs ? 'fixed-column-cell' : ''"
      :fixed="!isCurrentBreakPointXs"
      fixed-position="left"
    />
    <DxColumn
      :allow-header-filtering="false"
      :visible="isVisible('quantity_ordered')"
      :visible-index="columnIndex('quantity_ordered')"
      :min-width="80"
      sort-order="desc"
      data-field="quantity_ordered"
      :caption="!isCurrentBreakPointXs ? 'Units Sold' : 'U. Sold'"
      :width="!isCurrentBreakPointXs ? '120' : '80'"
      data-type="string"
      cell-template="units-sold-cell"
    />
    <DxColumn
      :allow-header-filtering="false"
      :visible="isVisible('orders_quantity')"
      :visible-index="columnIndex('orders_quantity')"
      data-field="orders_quantity"
      caption="Orders"
      data-type="string"
      cell-template="common-cell"
    />
    <DxColumn
      :allow-header-filtering="false"
      :visible="isVisible('revenue')"
      :visible-index="columnIndex('revenue')"
      data-field="revenue"
      caption="Revenue"
      data-type="string"
      :min-width="130"
      cell-template="money-cell"
    />
    <DxColumn
      :allow-header-filtering="false"
      :visible="isVisible('amazon_fees')"
      :visible-index="columnIndex('amazon_fees')"
      data-field="amazon_fees"
      caption="AMZ Fees"
      data-type="string"
      :min-width="130"
      cell-template="money-cell"
    />
    <DxColumn
      :allow-header-filtering="false"
      :visible="isVisible('net')"
      :visible-index="columnIndex('net')"
      :min-width="90"
      data-field="net"
      caption="Net"
      data-type="string"
      cell-template="money-cell"
    />
    <DxColumn
      :allow-header-filtering="false"
      :visible="isVisible('cost')"
      :visible-index="columnIndex('cost')"
      data-field="cost"
      caption="Cost"
      data-type="string"
      :min-width="90"
      cell-template="money-cell"
    />
    <DxColumn
      :allow-header-filtering="false"
      :visible="isVisible('profit')"
      :visible-index="columnIndex('profit')"
      :min-width="90"
      data-field="profit"
      caption="Profit"
      data-type="string"
      cell-template="money-cell"
    />

    <DxColumn
      :allow-sorting="false"
      :allow-header-filtering="false"
      :visible="isVisible('profit_indicator')"
      :visible-index="columnIndex('profit_indicator')"
      :min-width="60"
      data-field="profit_indicator"
      caption="Profit Margin"
      data-type="string"
      cell-template="margin-cell"
    />

    <DxColumn
      :allow-sorting="false"
      :allow-header-filtering="false"
      :visible="isVisible('profit_indicator_net')"
      :visible-index="columnIndex('profit_indicator_net')"
      :min-width="60"
      data-field="profit_indicator_net"
      caption="Net Margin"
      data-type="string"
      cell-template="net-margin-cell"
    />

    <DxMasterDetail
      :enabled="false"
      template="masterDetailTemplate"
    />

    <template #masterDetailTemplate="{ data }">
      <div style="max-height: 45vh; overflow: auto">
        <Chart
          v-if="isChartView"
          :data-source="chartData"
          :row-data="data"
        />
      </div>
    </template>

    <template #common-cell="{ data }">
      <div class="text-primary text-center">
        {{ data.value.display }}
      </div>
    </template>
    <template #units-sold-cell="{ data }">
      <div class="d-flex flex-column justify-content-center text-center">
        <div class="text-primary text-center">
          {{ data.value.display }}
        </div>
        <small
          v-if="data.key.promos.amount"
          class="text-primary"
        >{{ data.key.promos.display }} promos</small>
        <small
          v-if="data.key.refunds.amount"
          class="text-danger"
        >{{ data.key.refunds.display }} refunds</small>
        <small
          v-if="data.key.combined_reimbursements.amount"
          class="text-warning"
        >{{ data.key.combined_reimbursements.display }} reimbs</small>
      </div>
    </template>
    <template #money-cell="{ data }">
      <div
        class="text-center"
        :class="{ 'text-danger' : data.value.amount < 0, 'text-success': data.value.amount > 0 }"
      >
        {{ data.value.display }}
      </div>
    </template>
    <template #margin-cell="{ data }">
      <div class="d-flex flex-column justify-content-center text-center margin-cell">
        <div
          :class="{
            'text-success' : data.value.main.amount > 0,
            'text-danger' : data.value.main.amount <= 0,
          }"
        >{{ data.value.main.display }}</div>
        <div>ROI: <span
          :class="{
            'text-success' : data.value.alternative.amount > 0,
            'text-danger' : data.value.alternative.amount <= 0,
          }"
        >{{ data.value.alternative.display }}</span></div>
      </div>
    </template>
    <template #net-margin-cell="{ data }">
      <div class="d-flex flex-column justify-content-center text-center margin-cell">
        <div
          :class="{
            'text-success' : data.value.main.amount > 0,
            'text-danger' : data.value.main.amount <= 0,
          }"
        >{{ data.value.main.display }}</div>
      </div>
    </template>
    <template #acos-cell="{ data }">
      <div class="d-flex flex-column justify-content-center text-center acos-cell">
        <div
          :class="{
            'text-success' : data.value.current.amount > 0,
            'text-danger' : data.value.current.amount <= 0,
          }"
        >{{ data.value.current.display }}</div>
        <small>Break-even: {{ data.value.max.display }}</small>
        <b-badge>
          TACOS {{ data.data.tacos.current.display }}
        </b-badge>
      </div>
    </template>
    <template #image-cell="{ data }">
      <div
        class="d-flex flex-column align-items-center text-center"
      >
        <div
          v-if="!isTotals"
          class="image-container position-relative"
        >
          <ProductImage
            :size="!isCurrentBreakPointXs ? '59px' : '42px'"
            :src="data.data.image_url"
            :title="data.data.title"
            icon-size="32"
            :is-zoom-available="true"
            :marketplace="data.data.marketplace.marketplace_id"
            :marketplace-flag="data.data.marketplace.country_code"
            :asin="data.data.uuid"
            :product-url="data.data.product_url"
            :attr-class="!isCurrentBreakPointXs ? 'tw-p-4' : 'tw-p-1.5'"
          />
          <CountryFlag
            :key="data.data.marketplace.country_code"
            class="product-marketplace"
            :country="data.data.marketplace.country_code"
            :title="data.data.marketplace.country"
            :alt="data.data.marketplace.country"
            :size="isCurrentBreakPointXs ? 'small' : undefined"
          />
        </div>
        <CountryFlag
          v-else
          :key="data.data.marketplace.country_code"
          :country="data.data.marketplace.country_code"
          :title="data.data.marketplace.country"
          :alt="data.data.marketplace.country"
          :size="isCurrentBreakPointXs ? 'medium' : undefined"
        />

        <a
          v-if="!isCurrentBreakPointXs"
          class="text-white"
          :href="data.data.product_url"
          target="_blank"
        >
          <small>
            {{ data.data.uuid }}
          </small>
        </a>
        <small
          v-if="!isCurrentBreakPointXs"
          class="text-white"
        >{{ data.data.short_name }}
        </small>
      </div>
    </template>
    <template #buttons-cell="{ data }">
      <div
        class="tw-flex tw-flex-col justify-content-center tw-items-center"
        style="height: 100%"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-b-tooltip.hover.right="'Breakdown'"
          size="sm"
          class="btn-icon btn-xs"
          variant="flat-primary"
          @click.stop="onBreakdownClick(data)"
        >
          <b-spinner
            v-if="false"
            small
            type="grow"
          />
          <feather-icon
            v-else
            icon="ChevronsDownIcon"
          />
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-b-tooltip.hover.right="'List of orders'"
          size="sm"
          class="btn-icon btn-xs"
          variant="flat-primary"
          @click.stop="onOrdersClick(data)"
        >
          <feather-icon icon="GridIcon" />
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          v-b-tooltip.hover.right="'Charts'"
          size="sm"
          class="btn-icon btn-xs "
          variant="flat-primary"
          @click.stop="onChartClick(data)"
        >
          <b-spinner
            v-if="isChartDataLoading"
            small
            type="grow"
          />
          <feather-icon
            v-else
            icon="BarChart2Icon"
          />
        </b-button>
      </div>
    </template>
  </DxDataGrid>
</template>

<script>
import { DxColumn, DxDataGrid, DxMasterDetail } from 'devextreme-vue/data-grid'
import {
  BBadge, VBTooltip, BButton, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import CountryFlag from 'vue-country-flag'
import ProductImage from '@/components/ProductImage.vue'

const Chart = () => import(/* webpackChunkName: "chartComp" */ '@/views/sales/Chart.vue')

const MASTER_DETAIL_MODE_CHART = 'chart'

export default {
  name: 'DetailTemplate',
  components: {
    DxDataGrid,
    DxColumn,
    DxMasterDetail,
    BBadge,
    BButton,
    BSpinner,
    CountryFlag,
    Chart,
    ProductImage,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    visibleColumns: {
      type: Array,
      required: true,
    },
    visibleColumnsIndex: {
      type: Array,
      required: true,
    },
    filterParams: {
      type: Object,
      required: true,
    },
    isTotals: {
      type: Boolean,
      default: false,
    },
    expandedKey: {
      type: Object,
      default: undefined,
    },
    mode: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: true,
      dataSource: [],
      masterDetailViewMode: MASTER_DETAIL_MODE_CHART,
      expandedRows: {},
      isChartDataLoading: false,
      isChartDataLoaded: false,
      chartData: [],
      marketplace: undefined,
    }
  },
  computed: {
    isVisible() {
      return columnName => this.visibleColumns.includes(columnName)
    },
    columnIndex() {
      return columnName => {
        if (!this.visibleColumns.includes(columnName)) {
          return -1
        }
        return this.visibleColumnsIndex.find(column => column.name === columnName).index
      }
    },
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
    isCurrentBreakPointXs() {
      return this.currentBreakPoint === 'xs'
    },
    columnWidth() {
      return this.isCurrentBreakPointXs ? '100' : '100'
    },
    isChartView() {
      return this.masterDetailViewMode === MASTER_DETAIL_MODE_CHART
    },
  },
  mounted() {
    this.loadData()
    if (this.expandedKey) {
      if (this.mode === MASTER_DETAIL_MODE_CHART) {
        this.onChartClick({ key: this.expandedKey, rowIndex: -1 })
      }
    }
  },
  methods: {
    isEmpty(value) {
      return value === undefined || value === null || value.length === 0
    },
    loadData() {
      this.isLoading = true
      const payload = { ...this.filterParams }

      this.$refs.gridView.instance.beginCustomLoading('Loading...')
      if (!this.isTotals) {
        if (!payload.search || payload.search.length === 0) {
          payload.search = this.item.uuid
          payload.search_by = 'asin'
        }

        payload.filter_by_asin = [this.item.uuid]
        payload.asin = this.item.uuid

        this.$store.dispatch('sales/fetchSalesDetails', payload).then(result => {
          this.dataSource = result
        }).finally(() => {
          this.isLoading = false
          this.$refs.gridView.instance.endCustomLoading('Loading...')
        })
      } else {
        this.$store.dispatch('sales/fetchMarketplaceTotals', payload).then(result => {
          this.dataSource = result
        }).finally(() => {
          this.isLoading = false
          this.$refs.gridView.instance.endCustomLoading('Loading...')
        })
      }
    },
    onBreakdownClick({ key }) {
      if (this.isTotals) {
        const payload = { ...this.filterParams }
        if (this.marketplace) {
          payload.marketplaces = [key.marketplace.marketplace_id]
        }

        this.$store.dispatch('sales/fetchFeeDetails', payload).then(result => {
          this.$emit('on-breakdown-click', {
            fees: result,
            data: {
              marketplace: key.marketplace,
            },
          })
        }).finally(() => {
          this.isFeeDetailsLoading = false
        })
      } else {
        this.$emit('on-breakdown-click', {
          fees: this.item.fees,
          data: {
            marketplace: this.item.marketplace,
            asin: this.item?.uuid,
            title: this.item?.title,
            image: this.item?.image_url,
          },
        })
      }
    },
    onOrdersClick({ key }) {
      const filterParams = { ...this.filterParams }
      filterParams.marketplaces = [key.marketplace.marketplace_id]
      if ((!filterParams.search || filterParams.search.length === 0) && key.uuid) {
        filterParams.search_by = 'asin'
        filterParams.search = key.uuid
      } else {
        filterParams.filter_by_asin = [key.uuid]
        filterParams.search_by = 'all'
      }
      this.$emit('on-order-list-click', {
        filterParams,
        data: {
          marketplace: this.item.marketplace,
          asin: this.item?.uuid,
          title: this.item?.title,
          image: this.item?.image_url,
        },
      })
    },
    onChartClick({ key, rowIndex, data }) {
      this.marketplace = data.marketplace.marketplace_id
      if (this.expandedRows[rowIndex] && this.expandedRows[rowIndex] === MASTER_DETAIL_MODE_CHART) {
        this.$refs.gridView.instance.collapseRow(key)
        delete this.expandedRows[rowIndex]
        this.$emit('master-detail-collapse')
      } else {
        this.$emit('master-detail-expand', {
          mode: MASTER_DETAIL_MODE_CHART,
          key,
        })
        this.expandedRows[rowIndex] = MASTER_DETAIL_MODE_CHART
        this.masterDetailViewMode = MASTER_DETAIL_MODE_CHART
        this.$refs.gridView.instance.expandRow(key)

        if (!this.isChartDataLoaded) {
          this.isChartDataLoading = true
          const payload = { ...this.filterParams }
          if (this.isTotals) {
            payload.marketplaces = [this.marketplace]
          } else if (!payload.search || payload.search.length === 0) {
            payload.search = this.item.uuid
            payload.search_by = 'asin'
          }

          if (!this.isTotals) {
            payload.filter_by_asin = [this.item.uuid]
          }

          this.$store.dispatch('sales/fetchChartData', payload).then(result => {
            this.chartData = result.map(point => {
              const chartData = { ...point }
              chartData.date = moment(point.date).toDate()
              return chartData
            })
            this.isChartDataLoaded = true
            this.isChartVisible = true
          }).finally(() => {
            this.isChartDataLoading = false
          })
        }
      }
    },
  },
}
</script>

<style lang="scss">
  .marketplace-grid {
    .image-container {
      &:hover {
        .product-marketplace {
          display: none;
        }
      }
    }
    .product-marketplace {
      position: absolute;
      right: -17px;
      bottom: -2px;

      @media (max-width: 565px) {
        right: -10px;
      }
    }

    .dx-datagrid {
      .dx-datagrid-table {
        td {
          &.dx-datagrid-expand {
            pointer-events: none;
            visibility: hidden;
          }
        }
      }

      .margin-cell,
      .acos-cell {
        min-height: 75px;
        max-height: 75px;
      }
    }
  }
</style>
