<template>
  <b-modal
    v-model="isVisible"
    size="lg"
    title="Order List"
    :hide-footer="true"
  >
    <div
      v-if="isVisible"
      class="d-flex flex-column overflow-auto "
    >
      <div
        v-if="itemData.asin"
        class="tw-flex tw-flex-row align-items-end mb-2 xs:tw-p-4"
      >
        <div
          class="position-relative"
        >
          <b-avatar
            rounded
            :class="{ 'product-image-mobile': isCurrentBreakPointXs }"
            :size="!isCurrentBreakPointXs ? '60px' : '40px'"
            :src="itemData.image"
            :alt="itemData.asin"
            :title="itemData.title"
          >
            <feather-icon
              v-if="isEmpty(itemData.image)"
              size="32"
              icon="ImageIcon"
            />
          </b-avatar>
          <CountryFlag
            :key="itemData.marketplace.country_code"
            class="product-marketplace"
            :country="itemData.marketplace.country_code"
            :title="itemData.marketplace.country"
            :alt="itemData.marketplace.country"
            :size="isCurrentBreakPointXs ? 'small' : undefined"
          />
        </div>
        <div class="ml-2">
          {{ itemData.title }}
        </div>
      </div>
      <OrdersTable
        :filter-params="filterParams"
        :grid-height="gridHeight"
      />
    </div>
  </b-modal>
</template>

<script>
import { BModal, BAvatar } from 'bootstrap-vue'
import CountryFlag from 'vue-country-flag'
import OrdersDataSource from '@/data/orders.datasource'
import OrdersTable from '@/views/sales_v2/components/OrdersTable.vue'

export default {
  name: 'OrderListModal',
  components: {
    OrdersTable,
    BModal,
    BAvatar,
    CountryFlag,
  },
  props: {
    filterParams: {
      type: Object,
      default: () => ({}),
    },
    itemData: {
      type: Object,
      default: () => ({}),
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    gridHeight() {
      return !this.itemData.asin ? '100%' : '85%'
    },
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
    isCurrentBreakPointXs() {
      return this.currentBreakPoint === 'xs'
    },
    orderDataSource() {
      const filterParams = { ...this.filterParams }
      return OrdersDataSource.getInstance(this.$store, filterParams)
    },
    isVisible: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    isEmpty(value) {
      return value === undefined || value === null || value.length === 0
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .modal-body {
    display: flex;
    align-items: stretch;
    justify-content: center;
    height: 85vh;
  }
}

.product-marketplace {
  position: absolute;
  right: -17px;
  bottom: -2px;

  @media (max-width: 565px) {
    right: -10px;
  }
}
</style>
